<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card :is-refresh="isLoadingInitData">
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="PO Header" icon-pack="feather">
              <TabHeader :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Detail Barang/Jasa" icon-pack="feather">
              <TabBarang :isActive="activeTab === 1" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Termin Pembayaran" icon-pack="feather">
              <TabPembayaran :isActive="activeTab === 2" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <vx-card :is-refresh="isLoadingInitData">
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="PO-KONTRAK"
            :id-ref="$route.params.idPo"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <PoKontrakApprovalSigning :isActive.sync="modalSigning.active"
                       :idPo="modalSigning.idPo"
                       :status="modalSigning.status"
                       @success="onSigned"/>
  </div>
</template>

<script>
import modulePoKontrakApproval from '@/store/modules/approvals/po-kontrak-approval/po-kontrak-approval.store'
import PoRepository from '@/repositories/proyek/po-repository'

export default {
  name: 'PoKontrakApprovalShow',
  components: {
    PoKontrakApprovalSigning: () => import('@/views/pages/approvals/po-kontrak-approval/PoKontrakApprovalSigning'),
    TabHeader: () => import('@/views/pages/approvals/po-kontrak-approval/parts/TabHeader'),
    TabBarang: () => import('@/views/pages/approvals/po-kontrak-approval/parts/TabBarang'),
    TabPembayaran: () => import('@/views/pages/approvals/po-kontrak-approval/parts/TabPembayaran'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        header: {},
        barang: [],
        pembayaran: []
      },
      modalSigning: {
        active: false,
        idPo: this.$route.params.idPo,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.poKontrakApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/poKontrakApproval/SET_ACTIVE_TAB', value)
      }
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true
      const idPo = this.$route.params.idPo
      PoRepository.show(idPo)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'poKontrakApproval'], modulePoKontrakApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/poKontrakApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'poKontrakApproval'])
  }
}
</script>
